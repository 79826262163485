export default function IconButton() {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',

          '& .MuiTouchRipple-child': {
            borderRadius: '4px',
          },
          '& .MuiTouchRipple-rippleVisible': {
            borderRadius: '4px',
          },
        },
        sizeLarge: {
          width: 40,
          height: 40,
        },
        sizeMedium: {
          width: 40,
          height: 40,
        },
        sizeSmall: {
          width: 25,
          height: 25,
        },
      },
    },
  };
}
